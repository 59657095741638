<template>
    <div>
        <meet-title :meetName="infos.meetTitle" :meetDate="meetDate" />
        <div class="infoBox">
            <div>
                <template v-for='(item,index) in list'>
                <div :key='index' class="tripBox">
                    <div class='left'>
                        <span class="time">{{item.timeRange}}</span>
                        <span class="date">{{item.date}}</span>
                    </div>
                    <div class="middle">
                        <span class="circle"></span>
                        <span class="line"></span>
                        <span class="circle circle2" v-if='index==list.length-1'></span>
                    </div>
                    <div class="right">
                        <div class="title">{{item.scheduleTitle}}</div>
                        <div class='host'>
                            <img src="../../assets/host.png" />
                            <span>{{item.compere}}</span>
                        </div>
                        <div class="desc" v-if='item.scheduleDescribe'>
                            {{item.scheduleDescribe.substring(0,50)}}
                        </div>
                    </div>
                </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import MeetTitle from "./component/meetTitle.vue";
import moment from 'moment';

    export default {
        name:'meetSchedule',
        components:{          
            MeetTitle
        },
        data(){
            return{               
                infos:{},
                list:[]
            }
        },
        computed:{
            meetDate(){
                let str=this.infos.meetTimeStart.split('-').join('/')+"-"+this.infos.meetTimeEnd.split('-').join('/')
                return str;
            }
        },
        created(){            
            let infos=this.$route.params;
            this.infos=infos;
            this.list=this.$route.params.scheduleInfo.map(item=>{
                item.timeRange=moment(item.scheduleTimeStart).format("HH:mm")+"-"+moment(item.scheduleTimeEnd).format("HH:mm");
                item.date=moment(item.scheduleTimeStart).format("YYYY-MM-DD")
                return item;
            })
        },
  
    }
</script>

<style lang="less" scoped>
.infoBox{
    margin-top: -0.2rem;
    background: #fff;
    border-radius: 20px 20px 0px 0px;
    padding: 0.2rem 0.12rem 1rem;
    .tripBox{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 1.3rem;
        // border-bottom: 1px solid red;        
        &>div{
            display: flex;
            flex-direction: column;        
        }
        .left{
            width: 20%;
            align-items: flex-end;
            justify-content: space-evenly;
            font-size: 10px;
            .time{
                color: #333;
                font-weight: bold;
            }
            .date{
                color: #999;
                margin-top: 10px;
                
            }
        }
        .middle{
            align-items: center;
             justify-content: space-between;
             position: relative;
            transform: translateY(50%);
             .circle{
                 width: 8px;
                 height: 8px;
                 border-radius: 8px;
                 border: 1px solid #e5e5e5;
                 margin-top:-10px ;  
                 margin-left: 1px;               
             }
             .circle2{
                 position:absolute ;
                 bottom:-10px
             }
             .line{
                 width: 1px;
                 height: 1.1rem;
                 background:#e5e5e5 ;
                 margin:5px 0
             }
        }
        .right{
             width: 71%;
             align-items: flex-start;
             justify-content: space-evenly;
             height: 100%;
             border-bottom: 1px solid #eee;            
             font-size: 12px;
             color: #999;
             .title{                
                 color: #333;                
             }
             .host{
                 display: flex;
                 flex-direction: row;
                 align-items: center;
                 img{
                     width: 13px;
                     margin-right: 0.06rem;
                 }
             }
             .desc{
                 line-height: 20px;
             }
        }
    }
}
</style>